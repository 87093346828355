export const generateRandomCode = (length = 2) => {
	let code = ''
	for (let i = 0; i < length; i++) {
		code += s4()
	}
	return code
}

function s4() {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
}

export const moveItemInArrayFromIndexToIndex = (array, fromIndex, toIndex) => {
	const newArray = [...array]

	if (fromIndex === toIndex) return newArray

	const target = newArray[fromIndex]
	const inc = toIndex < fromIndex ? -1 : 1

	for (let i = fromIndex; i !== toIndex; i += inc) {
		newArray[i] = newArray[i + inc]
	}

	newArray[toIndex] = target

	return newArray
}
