import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
// import { TEPrivateRoute } from 'react-tec'

// import Front from './../layouts/Front/ContentWrapper'
const Front = lazy(() => import('./../layouts/Front/ContentWrapper'))
const Admin = lazy(() => import('./../layouts/Admin/ContentWrapper'))

const App = (props) => {
	return (
		<Suspense fallback={<div />}>
			<Switch>
				<Route
					path='/app-link/'
					render={() => {
						//Redirect to the main site because the user doesn't have the app donwloaded.
						window.location.replace('https://www.theklinkapp.com/')
					}}
				/>
				<Route path='/admin/' component={Admin} />
				<Route path='/' component={Front} />
			</Switch>
		</Suspense>
	)
}

export default App
