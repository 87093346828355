import React, { createContext, useReducer, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/database'
import { TEPopupContext } from 'react-tec'

import { docToDataObject } from './../helpers'
import settings from './../configs/settings'

export const LoadingContext = createContext()

const INITIAL_STATE = {
	loadingComplete: false,

	user: undefined,
	userLoaded: false,
	userWatcher: undefined,

	appVersion: undefined,
	appVersionLoaded: false,
	appVersionWatcher: undefined,
}

const reducer = (state, action) => {
	const { type, payload } = action

	switch (type) {
		case 'loading_complete':
			return { ...state, loadingComplete: true }
		case 'fetch_user':
			return { ...state, user: payload, userLoaded: true }
		case 'save_user_watcher':
			return { ...state, userWatcher: payload }
		case 'remove_user_watcher':
			return {
				...state,
				user: INITIAL_STATE.user,
				userLoaded: INITIAL_STATE.userLoaded,
				userWatcher: INITIAL_STATE.userWatcher,
			}
		case 'fetch_app_version':
			return { ...state, appVersion: payload, appVersionLoaded: true }
		case 'save_app_version_watcher':
			return { ...state, appVersionWatcher: payload }
		case 'remove_app_version_watcher':
			return {
				...state,
				appVersion: INITIAL_STATE.appVersion,
				appVersionLoaded: INITIAL_STATE.appVersionLoaded,
				appVersionWatcher: INITIAL_STATE.appVersionWatcher,
			}
		default:
			console.log(action)
			throw new Error()
	}
}

export const LoadingProvider = (props) => {
	const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
	const { dispatch: popupDispatch } = useContext(TEPopupContext)
	useAppLoading(state, dispatch, popupDispatch)

	return (
		<LoadingContext.Provider value={{ state, dispatch }}>
			{props.children}
		</LoadingContext.Provider>
	)
}

//
// 1) Initialize Firebase
// 2) Start Watching Authentication
// 3) Start Loading App Version
//

const useAppLoading = (state, dispatch, popupDispatch) => {
	//
	// 1) Initialize Firebase
	// 2) Start Watching Authentication
	// 3) Start Loading App Version
	//
	useEffect(() => {
		const {
			FIREBASE_API_KEY,
			FIREBASE_AUTH_DOMAIN,
			FIREBASE_DATABASE_URL,
			FIREBASE_PROJECT_ID,
			FIREBASE_STORAGE_BUCKET,
			FIREBASE_MESSAGING_SENDER_ID,
		} = settings

		firebase.initializeApp({
			apiKey: FIREBASE_API_KEY,
			authDomain: FIREBASE_AUTH_DOMAIN,
			databaseURL: FIREBASE_DATABASE_URL,
			projectId: FIREBASE_PROJECT_ID,
			storageBucket: FIREBASE_STORAGE_BUCKET,
			messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
		})

		//Authentication Watcher
		let userWatcher
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					const { uid } = user
					userWatcher = firebase
						.firestore()
						.doc(`Users/${uid}`)
						.onSnapshot(
							(snapshot) => {
								const user = docToDataObject(snapshot)

								if (!user.active) {
									firebase.auth().signOut()
									popupDispatch({
										type: 'show_alert',
										payload: {
											alertTitle: 'Account Deactivated',
											alertMessage:
												'This account is no longer active. If you believe this account was deactivated in error please contact support@trackit.com',
										},
									})
									dispatch({ type: 'remove_user_watcher' })
									dispatch({ type: 'loading_complete' })
									return
								}
								dispatch({ type: 'fetch_user', payload: docToDataObject(snapshot) })
							},
							(e) => {
								console.error(e)
								if (userWatcher) {
									userWatcher()
								}
								dispatch({ type: 'remove_user_watcher' })
							},
						)
					dispatch({ type: 'save_user_watcher', userWatcher })

					// const data = { uid, last_sign_in: new Date().getTime() }
					// apiRequest('POST', 'update_user', data)
				} else {
					if (userWatcher) {
						userWatcher()
					}
					dispatch({ type: 'remove_user_watcher' })
					dispatch({ type: 'loading_complete' })
				}
			},
			(e) => console.error(e),
			() => console.log('onAuthStateChanged Completed'),
		)

		//Start Loading App Version
		const appVersionWatcher = firebase
			.firestore()
			.doc('Versions/web')
			.onSnapshot(
				(snapshot) => {
					dispatch({ type: 'fetch_app_version', payload: docToDataObject(snapshot) })
				},
				(e) => {
					console.error(e)
					dispatch({ type: 'remove_app_version_watcher' })
				},
			)
		dispatch({ type: 'save_app_version_watcher', payload: appVersionWatcher })

		return () => {
			appVersionWatcher()
			dispatch({ type: 'remove_app_version_watcher' })
			if (userWatcher) {
				userWatcher()
			}
			dispatch({ type: 'remove_user_watcher' })
		}
	}, [dispatch, popupDispatch])
}
