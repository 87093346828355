import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import { TEAppWrapper } from 'react-tec'
import { IntlProvider } from 'react-intl'

import App from './App'
import { theme, globalStyles } from './globalStyles'
import { LoadingProvider, TitleBarProvider } from './contexts'

import 'react-table/react-table.css'
import './reactTable.css'

//TODO: Implement StrictMode.
//
// - react-tec throws errors because it relies on styled components and some other unsafe components
//

const Main = () => {
	return (
		<IntlProvider locale='en'>
			<TEAppWrapper theme={theme} globalStyles={globalStyles}>
				<LoadingProvider>
					<TitleBarProvider>
						<App />
					</TitleBarProvider>
				</LoadingProvider>
			</TEAppWrapper>
		</IntlProvider>
	)
}

ReactDOM.render(<Main />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
