const DEV = {
	FIREBASE_API_KEY: 'AIzaSyBXMcvrnKsY9b3TmtO4AVe-ez0PkS6kxb8',
	FIREBASE_AUTH_DOMAIN: 'klink-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://klink-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'klink-dev',
	FIREBASE_STORAGE_BUCKET: 'klink-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '487901139347',

	API_BASE_URL: 'https://gipj00zkw4.execute-api.us-east-1.amazonaws.com/dev',
}

const PROD = {
	FIREBASE_API_KEY: 'AIzaSyCiJnvMJUeI90NPxyrPwF8sWp_c45xEMWo',
	FIREBASE_AUTH_DOMAIN: 'klink-prod.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://klink-prod.firebaseio.com',
	FIREBASE_PROJECT_ID: 'klink-prod',
	FIREBASE_STORAGE_BUCKET: 'klink-prod.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '489583073018',

	API_BASE_URL: 'https://na3cgqm6zi.execute-api.us-east-1.amazonaws.com/prod',
}

export default {
	dev: true,
	// dev: process.env.NODE_ENV === 'development',
	// apiDebugging: true,
	apiDebugging: process.env.NODE_ENV === 'development',

	VERSION: '2.0.1',

	// EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	// ...DEV,
	// ...PROD,
	...(process.env.NODE_ENV === 'development' ? DEV : PROD),
}
