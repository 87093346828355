import axios from 'axios'

import firebase from 'firebase/app'
import 'firebase/auth'

import settings from 'configs/settings'

export const apiRequest = async (method, path, data) => {
	const { API_BASE_URL, apiDebugging } = settings

	const url = API_BASE_URL + '/' + path
	const { uid } = firebase.auth().currentUser

	const token = await firebase.auth().currentUser.getIdToken()
	const headers = { uid, token }

	if (apiDebugging) {
		console.log(`API REQUEST - ${path}:`, { method, url, headers, data })
	}

	const response = await axios({ method, url, headers, data })

	if (apiDebugging && response) {
		console.log(`API RESPONSE - ${path}:`, JSON.stringify(response))
	}

	return response
}

export const apiCatchAlert = ({ error, title: defaultTitle, message: defaultMessage }) => {
	const { dev } = settings

	if (dev) {
		console.log(error)
	}

	if (error && error.response && error.response.data) {
		const { title = defaultTitle, message = defaultMessage } = error.response.data

		return {
			alertTitle: title,
			alertMessage: message,
		}
	}

	return {
		alertTitle: defaultTitle,
		alertMessage: defaultMessage,
	}
}
