export const globalStyles = `
	@font-face {
		font-family: 'engraversgothicbtregular';
		src: url(${require('./assets/fonts/engraversgothicbt-webfont.woff2')}) format('woff2'),
			url(${require('./assets/fonts/engraversgothicbt-webfont.woff')}) format('woff');
		font-weight: normal;
		font-style: normal;
	}
	html,
	body {
		margin: 0px;
		padding: 0px;
		font-family: "engraversgothicbtregular", sans-serif;
		color: #1d1d1d;
	}
	* {
		font-family: "engraversgothicbtregular", sans-serif;
	}
`
export const theme = {
	primary: '#1d1d1d',
}
