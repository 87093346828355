import { format } from 'date-fns'

export const convertToDateTimeString = (epoch) => {
	if (!epoch || typeof epoch === 'string') {
		return ' -- '
	}

	return format(epoch, 'E, MMM d, yyyy, h:mm a')
}
export const convertToDateString = (epoch) => {
	if (!epoch || typeof epoch === 'string') {
		return ' -- '
	}

	return format(epoch, 'E, MMM d, yyyy')
}
export const convertToDateTimeShort = (epoch) => {
	if (!epoch || typeof epoch === 'string') {
		return ' -- '
	}

	return format(epoch, 'M/d/yyyy, h:mm a')
}
export const convertToDateShort = (epoch) => {
	if (!epoch || typeof epoch === 'string') {
		return ' -- '
	}

	return format(epoch, 'M/d/yyyy')
}
export const convertToTime = (epoch) => {
	if (!epoch || typeof epoch === 'string') {
		return ' -- '
	}

	return format(epoch, 'h:mm a')
}

export const formatPhoneNumber = (phonenumber) => {
	let formatedNumber = phonenumber
	formatedNumber = formatedNumber.replace(/\D/g, '')
	if (formatedNumber.length === 10) {
		formatedNumber = '+1' + formatedNumber
	}
	if (formatedNumber.length === 11) {
		formatedNumber = '+' + formatedNumber
	}
	return formatedNumber
}

export const displayPhoneNumber = (phoneNumber) => {
	if (!phoneNumber) {
		return null
	}

	//Remove "+1"
	let cleanedNumber = ''
	if (phoneNumber.charAt(0) === '+' && phoneNumber.charAt(1) === '1') {
		cleanedNumber = phoneNumber.slice(2)
	} else {
		cleanedNumber = ('' + phoneNumber).replace(/\D/g, '')
	}

	const numberParts = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/)
	return !numberParts ? null : '(' + numberParts[1] + ') ' + numberParts[2] + '-' + numberParts[3]
}

export const mapDayNumberToString = (dayNumber) => {
	switch (dayNumber) {
		case 0:
			return 'Sunday'
		case 1:
			return 'Monday'
		case 2:
			return 'Tuesday'
		case 3:
			return 'Wednesday'
		case 4:
			return 'Thursady'
		case 5:
			return 'Friday'
		case 6:
			return 'Saturday'
		default:
			console.warn('Invalid dayNumber provided to mapDayNumberToString', dayNumber)
			return dayNumber
	}
}
